<template>
    <div class="page_container">
        <div class="pc_warn">
            <p>{{ $t('为了更好的体验,请使用手机访问本页') }}</p>
            <p class="come_home_btn" @click="$router.push({ path: '/' })">{{ $t('返回首页') }}</p>
        </div>
        <div class=" page_bg" v-loading="dataLoading" :class="{ flex_end: isInFlight || bgPositionY < 100 }" :style="{
            backgroundPositionY: bgPositionY + '%', transition: mapMoveDelay / 100 + 's',
            backgroundSize: `${Math.max(100, backgroundSizeX)}%`
        }">
            <transition name="ease">
                <div v-show="!isInFlight" class="go_back_btn" @click="$router.push({ path: '/' })">
                    <img src="@/assets/images/rocket_active/icon_back.png" alt="">
                </div>
            </transition>

            <transition name="ease">
                <div v-show="!isInFlight && bgPositionY == 100" class="title_img">
                    <img src="@/assets/images/rocket_active/activily_title.png" alt="">

                </div>
            </transition>



            <div class="spine_container">
                <div class="model_wrap">
                    <!-- spine模型 -->
                    <canvas ref="spinePlayer"></canvas>
                </div>

                <div class="chassis"
                    :style="{ transform: `translateY(${-50 + (100 - bgPositionY) * 24}px)`, transition: mapMoveDelay / 100 + 's' }">
                    <img src="@/assets/images/rocket_active/icon_chassis.png" alt="">
                </div>
                <div class="btn_collection">
                    <div class="fill_btn_wrap mb-4">
                        <button type="button" class="jump_page_btn">
                            <animate-number :formatter="formatNumber" :from="nowFuel" :to="newFuel" :key="newFuel"
                                :duration="fuelDelay" easing="easeOutQuad"></animate-number>
                            <p class="btn_tips">{{ $t("总燃料") }}</p>
                        </button>
                    </div>

                    <div class="fill_btn_wrap" :class="{ opacity_0: isInFlight }">
                        <button @click="injectFuel(1)" :disabled="injectLoading" type="button" class="fill_btn mr-2">
                            <span>{{ $t("添加一次") }}</span>
                            <!-- <p class="btn_tips">{{ $t("耗油量") }}100</p> -->
                        </button>
                        <button @click="injectFuel(10)" :disabled="injectLoading" type="button" class="fill_btn">
                            <span>{{ $t("添加十次") }}</span>
                            <!-- <p class="btn_tips">{{ $t("耗油量") }}1000</p> -->
                        </button>

                    </div>

                </div>

            </div>

            <!-- 里程碑 -->
            <transition name="fade">
                <div class="mileage_mask" v-if="milestoneShow" @click.self="milestoneShow = false">
                    <div class="mileage_container">
                        <ul class="mileage_content" ref="mileageContent">
                            <li :class="item.level <= activityInfo.now_level ? 'mileage_item_light' : 'mileage_item'"
                                v-for="(item, index) in activityInfo.activity_level">
                                <div class="mileage_data "
                                    :class="{ locationView: activityInfo.now_level == item.level, active: activityInfo.now_level == item.level || nowAcitveMileageId == item.id, mileage_light: activityInfo.now_level <= item.level }">
                                    <div class="mileage_txt"> {{ item.sec_mileage_max }}{{ item.mileage_unit }} {{
                                        $t('已达成') }}
                                        <button @click="changeActive(item.id)"
                                            v-if="activityInfo.now_level != item.level && nowAcitveMileageId != item.id && item.is_get == 1"><img
                                                class="tips_icon" src="@/assets/images/rocket_active/icon_warn.png"
                                                alt=""></button>
                                    </div>

                                    <button @click="oepnPrizePop(index)"
                                        v-if="(activityInfo.now_level == item.level || nowAcitveMileageId == item.id) && item.is_get == 1"
                                        type="button" class="prize_btn">{{ $t('领奖') }}</button>
                                </div>
                            </li>
                        </ul>

                        <button @click="milestoneShow = false" type="button" class="cancel_btn">{{ $t('取消') }}</button>
                    </div>
                </div>
            </transition>

            <!-- 每日任务 -->
            <transition name="fade">
                <div class="task_container" v-if="taskShow">
                    <div class="task_wrap">
                        <h3>{{ $t('每日任务') }}</h3>
                        <div class="task_head">
                            <img src="@/assets/images/rocket_active/task_head.png" alt="">
                        </div>

                        <div class="task_item">
                            <img src="@/assets/images/rocket_active/icon_dice.png" alt="">
                            <div class="task_content">
                                <span class="task_name">{{ $t("每日下注") }}</span>
                                <span>{{ $t("每日投注") }}{{ '100.00/500.00' }}BRL</span>
                            </div>
                        </div>

                        <div class="task_item">
                            <img src="@/assets/images/rocket_active/icon_game.png" alt="">
                            <div class="task_content">
                                <span class="task_name">{{ $t("专属游戏") }}</span>
                                <span>{{ $t("专属游戏投注") }}{{ '100.00/500.00' }}BRL</span>
                            </div>
                        </div>

                        <div class="task_item">
                            <img src="@/assets/images/rocket_active/icon_chip.png" alt="">
                            <div class="task_content">
                                <span class="task_name">{{ $t("累计下注") }}</span>
                                <span>{{ $t("投注总额") }}{{ '100.00/500.00' }}BRL</span>
                            </div>
                        </div>
                    </div>

                    <button @click="taskShow = false" type="button" class="cancel_btn">{{ $t('取消') }}</button>
                </div>
            </transition>

            <!-- 活动说明 -->
            <transition name="fade">
                <div class="task_container" v-if="illustrate" @click="illustrate = false">
                    <div class="task_wrap">
                        <h3>{{ $t('活动说明') }}</h3>
                        <div v-html="illustrateContent" class="illustrate_content"></div>
                    </div>
                    <button @click="illustrate = false" type="button" class="cancel_btn">{{ $t('取消') }}</button>
                </div>
            </transition>


            <!-- 礼品 -->
            <transition name="ease">
                <div class="record_mask" v-if="prizeShow" @click.self="closePrize">
                    <div class="gift_box">
                        <img v-if="openGiftLoading" class="price_glare"
                            src="@/assets/images/rocket_active/price_glare.png" alt="">
                        <img v-else src="@/assets/images/rocket_active/rocket_glare.png" alt="">

                        <div class="gift_content">
                            <div v-if="!drapPrizeEnd" @click="drawPrize" class="git_img "
                                :class="{ draw_prize_animation: openGiftLoading }">
                                <img src="@/assets/images/rocket_active/icon_gift_box.png" alt="">
                            </div>
                            <div class="coin_img " v-if="drapPrizeEnd">
                                <img src="@/assets/images/rocket_active/icon_gold_coin.png" alt="">
                            </div>
                            <transition name="ease">
                                <div class="git_content" v-if="!openGiftLoading && !drapPrizeEnd">
                                    <p class="prize_txt">New prize</p>
                                    <button @click="closePrize" type="button" class="cancel_sm_btn">{{ $t('取消')
                                        }}</button>
                                </div>
                            </transition>
                            <transition name="ease">
                                <div class="coin_content" v-if="drapPrizeEnd">
                                    <p class="congrats_txt money_txt">{{ $t('恭喜') }}</p>
                                    <p class="money_txt text-xl"><animate-number :formatter="formatNumber" from="0"
                                            :to="awardValue" :key="1" duration="1500"
                                            easing="easeOutQuad"></animate-number>BRL</p>
                                    <button @click="closePrize" type="button" class="cancel_sm_btn2">{{ $t('取消')
                                        }}</button>
                                </div>
                            </transition>

                        </div>
                    </div>
                </div>
            </transition>

            <!-- 记录 -->
            <transition name="ease">
                <div class="record_mask" v-if="recordShow" @click.self="recordShow = false">
                    <div class="record_container">
                        <div class="record_close_btn" @click="recordShow = false">
                            <img class="close_btn" src="@/assets/images/rocket_active/close_btn.png" alt="">
                        </div>
                        <h3 class="record_title">{{ $t("我的记录") }}</h3>
                        <div class=" tab_wrap">
                            <div @click="changeTab(0)" class="tab_btn" :class="{ 'active': tabActive == 0 }">
                                {{ $t("奖励记录") }}
                            </div>
                            <div @click="changeTab(1)" class="tab_btn" :class="{ 'active': tabActive == 1 }">
                                {{ $t("燃料记录") }}
                            </div>
                        </div>

                        <ul class="record_content" v-loading="recordLoading" v-if="tabActive == 0">
                            <li class="record_items items_head">
                                <span>{{ $t("时间") }}</span>
                                <span>{{ $t("类型") }}</span>
                                <span>{{ $t("数量") }}</span>
                            </li>
                            <li v-for="(item, index) in recordData" :key="index" class="record_items">
                                <span>{{ item.create_time }}</span>
                                <span>{{ item.award_type == 1 ? $t('余额') : $t('积分') }}</span>
                                <span>{{ item.award_value }}</span>
                            </li>
                        </ul>

                        <ul class="record_content" v-loading="recordLoading" v-else>
                            <li class="record_items items_head">
                                <span>{{ $t("时间") }}</span>
                                <span>{{ $t("次数") }}</span>
                                <span>{{ $t("前进里程") }}</span>
                                <span>{{ $t("累计里程") }}</span>
                            </li>
                            <li v-for="(item, index) in recordData" :key="index" class="record_items">
                                <span>{{ item.create_time }}</span>
                                <span>{{ item.num }}</span>
                                <span>{{ item.mileage }}</span>
                                <span>{{ item.all_mileage }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </transition>

            <transition name="fade">
                <div class="bottom_menu" v-show="!isInFlight">
                    <div class="menu_item" v-for="item in bottomMenu" :key="item.index" @click="changePage(item.index)">
                        <img :src="item.iconUrl" alt="">
                        <span>{{ $t(item.label) }}</span>
                    </div>
                    <!-- <div class="menu_item" @click="illustrate = true">
                        <img src="@/assets/images/rocket_active/icon_activily.png" alt="">
                        <span>{{ $t("活动说明") }}</span>
                    </div>
                    <div class="menu_item" @click="milestoneShow = true">
                        <img src="@/assets/images/rocket_active/icon_exchange.png" alt="">
                        <span>{{ $t("里程碑") }}</span>
                    </div>
                    <div class="menu_item" @click="recordShow = true">
                        <img src="@/assets/images/rocket_active/icon_my_record.png" alt="">
                        <span>{{ $t("我的记录") }}</span>
                    </div> -->
                </div>
            </transition>

            <div class="animation-ele">
                <div class="animation-content"
                    :style="{ transform: `translateY(${-50 + (100 - bgPositionY) * 24}px)`, transition: mapMoveDelay / 100 + 's' }">
                    <div class="wind-left">
                        <img src="@/assets/images/rocket_active/wind_left.png" alt="">
                    </div>
                    <div class="wind-right">
                        <img src="@/assets/images/rocket_active/wind_right.png" alt="">
                    </div>
                    <div class="could_sm">
                        <img src="@/assets/images/rocket_active/could_sm.png" alt="">
                    </div>
                    <!-- <div class="rain_Cloud">
                        <img src="@/assets/images/rocket_active/rain_Cloud.png" alt="">
                    </div> -->

                    <div class="satellite">
                        <img src="@/assets/images/rocket_active/satellite.png" alt="">
                    </div>
                    <div class="satellite_md">
                        <img src="@/assets/images/rocket_active/satellite_md.png" alt="">
                    </div>
                    <div class="star_bg">
                        <img src="@/assets/images/rocket_active/star.png" alt="">
                    </div>
                    <div class="star_bg2">
                        <img src="@/assets/images/rocket_active/star.png" alt="">
                    </div>
                    <div class="star_bg">
                        <img src="@/assets/images/rocket_active/star2.png" alt="">
                    </div>

                    <div class="nebula">
                        <img src="@/assets/images/rocket_active/nebula.png" alt="">
                    </div>
                    <div class="nebula2">
                        <img src="@/assets/images/rocket_active/nebula1.png" alt="">
                    </div>
                </div>

            </div>


        </div>
    </div>
</template>

<script>
import * as PIXI from 'pixi.js'
import { Spine } from 'pixi-spine'
import { get_box_list, get_fuel_list, get_rocket_activity_info, get_rocket_activity_illustrate, open_prize, inject_fuel } from "@/api/rocket.js"
export default {
    data() {
        return {
            dialogShow: false,
            tabActive: 0,
            recordData: [],
            recordShow: false,
            recordLoading: true,
            activityInfo: {},
            max_mileage: 0,
            milestoneShow: false,
            taskShow: false,
            illustrate: false,
            illustrateContent: "",
            prizeShow: false,
            openGiftLoading: false,
            drapPrizeEnd: false,
            awardValue: 0,
            isInFlight: false,
            injectLoading: false,
            nowFuel: 0,
            newFuel: 0,
            fuelDelay: 1500,
            bgPositionY: 100,
            backgroundSizeX: 130,
            mapMoveDelay: 1,
            dataLoading: true,
            publicPath: process.env.VUE_APP_PUBLIC_PATH,
            $_dragonCage: null,
            $_app: null,
            spineBox: null,
            activePage: null,
            bottomMenu: [
                {
                    label: '每日任务',
                    index: 0,
                    iconUrl: require('@/assets/images/rocket_active/icon_task.png'),
                    lighiIconUrl: require('@/assets/images/rocket_active/light_task.png')
                },
                {
                    label: '活动说明',
                    index: 1,
                    iconUrl: require('@/assets/images/rocket_active/icon_activily.png'),
                    lighiIconUrl: require('@/assets/images/rocket_active/light_activily.png')
                },
                {
                    label: '里程碑',
                    index: 2,
                    iconUrl: require('@/assets/images/rocket_active/icon_exchange.png'),
                    lighiIconUrl: require('@/assets/images/rocket_active/light_exchange.png')
                },
                {
                    label: '我的记录',
                    index: 3,
                    iconUrl: require('@/assets/images/rocket_active/icon_my_record.png'),
                    lighiIconUrl: require('@/assets/images/rocket_active/light_my_record.png')
                }

            ],
            nowAcitveMileageId: 0,
            receiveKey: 0,
            receiveLoading: false
        };
    },
    mounted() {
        if (!this.$store.state.isLoginStatu) {
            this.$router.push('/?modal=LoginRegistration&data=tab-0')
            return
        }
        this.fetch_rocket_info()
        this.fetch_record()
        this.fetch_activity_illustrate()

    },
    methods: {
        changeActive(id) {
            this.nowAcitveMileageId = id
        },
        changePage(key) {
            this.activePage = key
            switch (key) {
                case 0:
                    this.taskShow = true
                    break;
                case 1:
                    this.illustrate = true
                    break;
                case 2:
                    this.fetch_rocket_info()
                    this.milestoneShow = true
                    this.$nextTick(() => {
                        const highlightedElement = this.$refs.mileageContent.querySelector('.locationView')
                        if (highlightedElement) {
                            highlightedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        } else {
                            this.$refs.mileageContent.scrollTop = this.$refs.mileageContent.scrollHeight;
                        }
                    })
                    break
                case 3:
                    this.recordShow = true
                    break;
            }
        },
        async injectFuel(type) {

            this.injectLoading = true
            let fuelNum;
            if (type === 1) {
                fuelNum = 1
            } else {
                fuelNum = 10
            }
            let params = {
                rid: this.activityInfo.rid,
                num: fuelNum
            }
            const response = await inject_fuel(params)
            if (response.data.code == 1) {

                this.isInFlight = true
                this.injectLoading = false

                if (this.activityInfo.now_mileage == 0) {
                    const animationTime = response.data.data.add_mileage * 100;
                    this.startFly(animationTime)
                    setTimeout(() => {
                        this.changeFuelNums(this.newFuel - response.data.data.use_fuel, animationTime)
                        this.mapMoveDelay = animationTime / 10
                        this.bgPositionY = this.bgPositionY - response.data.data.add_mileage / this.max_mileage * 100
                        this.bgPositionY = Math.max(this.bgPositionY, 0)
                        this.backgroundSizeX = this.backgroundSizeX - response.data.data.add_mileage / this.max_mileage * 100
                        console.log(this.backgroundSizeX, 'this.backgroundSizeX ');
                        this.$set(this.activityInfo, 'now_mileage', response.data.data.all_mileage)
                        this.$set(this.activityInfo, 'now_level', response.data.data.now_level)
                        this.$set(this.activityInfo, 'activity_level', response.data.data.activity_level)
                        setTimeout(() => {
                            this.isInFlight = false
                        }, animationTime)
                    }, 1000)


                } else {
                    const animationTime = response.data.data.add_mileage * 100;
                    this.startFly(animationTime)
                    this.changeFuelNums(this.newFuel - response.data.data.use_fuel, animationTime)
                    this.mapMoveDelay = animationTime / 10
                    this.bgPositionY = this.bgPositionY - response.data.data.add_mileage / this.max_mileage * 100
                    this.bgPositionY = Math.max(this.bgPositionY, 0)
                    this.backgroundSizeX = this.backgroundSizeX - response.data.data.add_mileage / this.max_mileage * 100
                    console.log(this.backgroundSizeX, 'this.backgroundSizeX ');
                    this.$set(this.activityInfo, 'now_mileage', response.data.data.all_mileage)
                    this.$set(this.activityInfo, 'now_level', response.data.data.now_level)
                    this.$set(this.activityInfo, 'activity_level', response.data.data.activity_level)
                    setTimeout(() => {
                        this.isInFlight = false
                    }, animationTime)

                }


            } else {
                this.$message.error(response.data.msg);
                this.injectLoading = false
            }

        },

        closePrize() {
            this.prizeShow = false
            this.openGiftLoading = false
            this.drapPrizeEnd = false
            this.awardValue = 0
        },
        async drawPrize() {
            if (this.receiveLoading) {
                return
            }
            this.openGiftLoading = true;
            this.receiveLoading = true
            const response = await open_prize({ rid: this.activityInfo.rid, level: this.activityInfo.activity_level[this.receiveKey].level })
            if (response.data.code == 1) {
                this.awardValue = response.data.data.award_value
                this.drapPrizeEnd = true
                this.nowAcitveMileageId = 0
                this.activityInfo.activity_level[this.receiveKey].is_get = 0
                this.$set(this.activityInfo, 'now_get_level', response.data.data.now_get_level)
                this.fetch_record()
            } else {
                this.openGiftLoading = false;
                this.$message.error(response.data.msg);
            }
            this.receiveLoading = false
        },
        oepnPrizePop(key) {
            this.receiveKey = key
            this.milestoneShow = false
            setTimeout(() => {
                this.prizeShow = true
            }, 300)

        },
        changeTab(key) {
            if (key == this.tabActive) return
            this.tabActive = key
            this.fetch_record()
        },

        async fetch_record() {
            this.recordData = []
            this.recordLoading = true
            let params = {
                page: 1,
                limit: 999
            }
            if (this.tabActive == 0) {
                const response = await get_box_list(params)
                if (response.data.code == 1) {
                    this.recordData = response.data.data
                }
            } else {
                const response = await get_fuel_list(params)
                if (response.data.code == 1) {
                    this.recordData = response.data.data
                }
            }
            this.recordLoading = false
        },

        async fetch_rocket_info() {
            const response = await get_rocket_activity_info()
            if (response.data.code == 1) {
                this.activityInfo = response.data.data
                this.max_mileage = response.data.data.max_mileage
                this.changeFuelNums(response.data.data.all_remain_fuel, 1500)
                this.bgPositionY = 100 - response.data.data.now_mileage / this.max_mileage * 100
                this.dataLoading = false
                this.init()
            } else {
                this.dataLoading = false
                this.$message.error(response.data.msg);
            }
        },

        changeFuelNums(nums, delay) {
            this.fuelDelay = delay
            let cahcheNUms = this.newFuel
            this.newFuel = nums
            this.nowFuel = cahcheNUms
        },

        async fetch_activity_illustrate() {
            const response = await get_rocket_activity_illustrate()
            if (response.data.code == 1) {
                this.illustrateContent = response.data.data
            }

        },


        init() {
            const that = this
            that.$_app = new PIXI.Application({
                antialias: true,
                backgroundAlpha: 0,
                resolution: 1,
                view: this.$refs.spinePlayer
            });
            that.$_app.stop()
            const baseUrl = process.env.BASE_URL;
            that.$_app.loader
                .add('goblins', `${baseUrl}spine/t66huojian.json`)
                .load(onAssetsLoaded)

            that.$_app.stage.interactive = true
            function onAssetsLoaded(loader, res) {
                that.spineBox = new Spine(res.goblins.spineData)
                that.spineBox.skeleton.setSkinByName('default')
                that.spineBox.skeleton.setSlotsToSetupPose()
                that.$_dragonCage = new PIXI.Container()
                that.$_dragonCage.addChild(that.spineBox)
                const localRect = that.spineBox.getLocalBounds()
                that.spineBox.position.set(-localRect.x, -localRect.y + 45)
                const scale = Math.min(
                    that.$_app.screen.width / that.$_dragonCage.width,
                    that.$_app.screen.height / that.$_dragonCage.height - 0.05
                )
                that.$_dragonCage.scale.set(scale, scale)
                that.$_dragonCage.position.set(
                    (that.$_app.screen.width - that.$_dragonCage.width) * 0.5,
                    (that.$_app.screen.height - that.$_dragonCage.height) * 0.5
                )
                that.$_app.stage.addChild(that.$_dragonCage)
                if (that.activityInfo.now_mileage == 0) {

                    that.spineBox.state.addAnimation(0, '1daiji', true, 0)
                } else {
                    that.spineBox.state.addAnimation(0, '1feixing', true, 0)
                }
            }
            that.$_app.start()
        },
        startFly(animationTime) {
            let times = animationTime / 1000 - 2
            if (this.activityInfo.now_mileage == 0) {

                this.spineBox.state.addAnimation(0, '1dianhuo', true, 0)
                this.spineBox.state.addAnimation(0, '1feixing', true, 0)
                this.spineBox.state.addAnimation(0, '1js_feixing', true, 1)
                this.spineBox.state.addAnimation(0, '1js_jieshu', false, times)
                this.spineBox.state.addAnimation(0, '1feixing', true, 0)
            } else {
                this.spineBox.state.addAnimation(0, '1js_dianhuo', false, 0)
                this.spineBox.state.addAnimation(0, '1js_feixing', true, 0)
                this.spineBox.state.addAnimation(0, '1js_jieshu', false, times)
                this.spineBox.state.addAnimation(0, '1feixing', true, 0)
            }
        }

    },
    beforeDestroy() {
        if (this.$_dragonCage) {
            this.$_dragonCage.destroy(true)
        }
        if (this.$_app) {
            this.$_app.destroy(true)
        }
    },
};
</script>

<style lang="less" scoped src="@/assets/css/rockkActivity.less"></style>