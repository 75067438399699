import request from '@/utils/request'


// 宝箱开启记录
export function get_box_list(data) {
    return request({
        url: '/rocket_activity/get_box_list',
        method: 'post',
        data
    })
}

// 燃料记录
export function get_fuel_list(data) {
    return request({
        url: '/rocket_activity/get_fuel_list',
        method: 'post',
        data
    })
}

// 燃料记录
export function get_rocket_activity_info(data) {
    return request({
        url: '/rocket_activity/join',
        method: 'post',
        data
    })
}

// 活动说明
export function get_rocket_activity_illustrate(data) {
    return request({
        url: '/rocket_activity/info',
        method: 'post',
        data
    })
}


// 开奖
export function open_prize(data) {
    return request({
        url: '/rocket_activity/open_box',
        method: 'post',
        data
    })
}

// 开奖
export function inject_fuel(data) {
    return request({
        url: '/rocket_activity/inject',
        method: 'post',
        data
    })
}


